import React from 'react';
import styles from './addTaskButton.module.sass';
// import getSkin from './skin.js';
// import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import {checkIfPresent} from 'util/utilFunctions';

const AddTaskButton = (props) => {

	const skin = props.skinGuide.globalProfiles.palette
    let color = skin.secondaryColor;
    if (checkIfPresent(props.svgColor)) {
		color = props.svgColor;
	}
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 18 18" fill="none">
				<path d="M0 9C0 10.78 0.527841 12.5201 1.51677 14.0001C2.50571 15.4802 3.91131 16.6337 5.55585 17.3149C7.20038 17.9961 9.00998 18.1743 10.7558 17.8271C12.5016 17.4798 14.1053 16.6226 15.364 15.364C16.6226 14.1053 17.4798 12.5016 17.8271 10.7558C18.1743 9.00998 17.9961 7.20038 17.3149 5.55585C16.6337 3.91131 15.4802 2.50571 14.0001 1.51677C12.5201 0.527841 10.78 0 9 0C6.61388 0.00269279 4.32626 0.951769 2.63901 2.63901C0.951769 4.32626 0.00269279 6.61388 0 9ZM3.91304 8.60869C3.91304 8.40113 3.9955 8.20207 4.14226 8.05531C4.28903 7.90854 4.48809 7.82609 4.69565 7.82609H7.63043C7.68232 7.82609 7.73209 7.80547 7.76878 7.76878C7.80547 7.73209 7.82609 7.68232 7.82609 7.63043V4.69565C7.82609 4.48809 7.90854 4.28903 8.05531 4.14226C8.20208 3.9955 8.40113 3.91304 8.6087 3.91304H9.3913C9.59886 3.91304 9.79792 3.9955 9.94469 4.14226C10.0915 4.28903 10.1739 4.48809 10.1739 4.69565V7.63043C10.1739 7.68232 10.1945 7.73209 10.2312 7.76878C10.2679 7.80547 10.3177 7.82609 10.3696 7.82609H13.3043C13.5119 7.82609 13.711 7.90854 13.8577 8.05531C14.0045 8.20207 14.087 8.40113 14.087 8.60869V9.3913C14.087 9.59886 14.0045 9.79792 13.8577 9.94469C13.711 10.0915 13.5119 10.1739 13.3043 10.1739H10.3696C10.3177 10.1739 10.2679 10.1945 10.2312 10.2312C10.1945 10.2679 10.1739 10.3177 10.1739 10.3696V13.3043C10.1739 13.5119 10.0915 13.711 9.94469 13.8577C9.79792 14.0045 9.59886 14.087 9.3913 14.087H8.6087C8.40113 14.087 8.20208 14.0045 8.05531 13.8577C7.90854 13.711 7.82609 13.5119 7.82609 13.3043V10.3696C7.82609 10.3177 7.80547 10.2679 7.76878 10.2312C7.73209 10.1945 7.68232 10.1739 7.63043 10.1739H4.69565C4.48809 10.1739 4.28903 10.0915 4.14226 9.94469C3.9955 9.79792 3.91304 9.59886 3.91304 9.3913V8.60869Z"
				fill={color} />
			</svg>
		</div>
	);
}

export default applyWrappers(AddTaskButton, styles);