const cardState = {
	SELECTING_TASKS: 'SELECTING_TASKS',
	SETTING_PRIORITY: 'SETTING_PRIORITY',
	BLOCKED_STATE: 'BLOCKED_STATE',
	RESOLVED_STATE: 'RESOLVED_STATE',
	EXECUTION_STATE: 'EXECUTION_STATE',
	POPUP_STATE: 'POPUP_STATE'
};

export default cardState;
