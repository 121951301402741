const actionIds = {
	UPDATE_PRD: 11,
	REPLAN_SPRINT: 3
};

const actionOptionIds = {
	UPDATE_PRD: 14,
	REPLAN_SPRINT: 4
};

export {
	actionIds,
	actionOptionIds
};