import React from 'react';
import styles from './blocker.module.sass';
// import getSkin from './skin.js';
import applyWrappers from 'wrappers/ComponentWrapper';

const Blocker = (props) => {

	// const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 19 19" fill="none">
				<path d="M18.5408 0.455529C18.3978 0.312582 18.2279 0.199278 18.041 0.122115C17.8541 0.0449508 17.6537 0.00544511 17.4515 0.00586265H1.55088C1.14114 0.00816451 0.748985 0.172601 0.460147 0.46322C0.171308 0.753839 0.00928427 1.147 0.0095 1.55674L0 7.36836C0.0210184 10.0807 0.961001 12.7058 2.66646 14.8151C4.37192 16.9243 6.74209 18.393 9.38996 18.9813C9.49444 19.0027 9.60215 19.0027 9.70662 18.9813C12.349 18.3692 14.7057 16.8792 16.3919 14.7547C18.0782 12.6302 18.9942 9.99679 18.9905 7.28445L19 1.54724C18.9996 1.34392 18.9588 1.14271 18.88 0.955292C18.8012 0.767877 18.6859 0.597998 18.5408 0.455529ZM14.4788 12.1294C14.3755 12.2953 14.2317 12.4322 14.061 12.5272C13.8902 12.6222 13.6981 12.6722 13.5027 12.6725H5.49733C5.30192 12.6722 5.1098 12.6222 4.93903 12.5272C4.76827 12.4322 4.62449 12.2953 4.52121 12.1294C4.41927 11.9626 4.3622 11.7722 4.35555 11.5767C4.34889 11.3813 4.39286 11.1874 4.48321 11.014L8.48588 3.38707C8.58357 3.20287 8.72959 3.04875 8.90825 2.94126C9.08692 2.83378 9.29149 2.77699 9.5 2.77699C9.70851 2.77699 9.91308 2.83378 10.0917 2.94126C10.2704 3.04875 10.4164 3.20287 10.5141 3.38707L14.5168 11.014C14.6071 11.1874 14.6511 11.3813 14.6445 11.5767C14.6378 11.7722 14.5807 11.9626 14.4788 12.1294Z" fill="#D63228" />
				<path d="M9.50004 11.4837C9.93727 11.4837 10.2917 11.1293 10.2917 10.6921C10.2917 10.2548 9.93727 9.90039 9.50004 9.90039C9.06282 9.90039 8.70837 10.2548 8.70837 10.6921C8.70837 11.1293 9.06282 11.4837 9.50004 11.4837Z" fill="#D63228" />
				<path d="M9.5 9.30664C9.65747 9.30664 9.80849 9.24408 9.91984 9.13274C10.0312 9.02139 10.0938 8.87036 10.0938 8.71289V6.33789C10.0938 6.18042 10.0312 6.0294 9.91984 5.91805C9.80849 5.8067 9.65747 5.74414 9.5 5.74414C9.34253 5.74414 9.19151 5.8067 9.08016 5.91805C8.96881 6.0294 8.90625 6.18042 8.90625 6.33789V8.71289C8.90625 8.87036 8.96881 9.02139 9.08016 9.13274C9.19151 9.24408 9.34253 9.30664 9.5 9.30664Z" fill="#D63228" />
			</svg>
		</div>
	);
}

export default applyWrappers(Blocker, styles);